import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import './App.scss';
import arrow from './icon-arrow.svg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App()
{
  const [inputValue, setInputValue] = useState('');
  const notify = () => toast.error("Please Insert a valid Email!");
  const send_Email = (email) => {
    emailjs.send(
      'techMentors',
      'techMentors',
      { message: "Hello Tech Mentors! I just Sign Up with your New website so please don't forget me " + email, name: "New Visitor", subject: "Downloaded Your Cv" }, 'RtHlmiUUCGWC5sxlu'
    ).then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
    });
    setInputValue('')
  };
  return (
    <>
    <ToastContainer/>
    <div className="App">
      <div className='md:w-[50vw] w-full h-full flex flex-col items-center md:items-start text-center md:text-left justify-between lg:px-20 px-5 md:py-20 pb-20 gap-[50px]'>
          <h1 className='font-bold text-2xl md:text-3xl text-[#431a84]'>Tech Mentors</h1>
          <div className='flex flex-col sm:gap-[50px] gap-10 lg:w-[550px]'>
            <h2 className='text-4xl sm:text-5xl md:text-7xl'>WE'RE<br/>COMING SOON</h2>
            <p className='text-[#686868] text-md'>Hello <b>Developers</b> everywhere! We're currently building our new Mentorship Website that will help you get your carrier to the next level, so what are you waiting for! Add your E-mail below to stay up-to-date with announcements and our launch deals.</p>
            <div className='input_container'> 
            <input type='email' placeholder='Email Address...' value={inputValue} onChange={(e) => setInputValue(e.target.value)} />
              <div className='arrow_container' onClick={() => inputValue.length > 10 ? send_Email(inputValue) : notify()}><img src={arrow} alt="Arrow Icon" width={20} height={20} /></div>
            </div>
          </div>
          <div>
            <span>And of course if you have anything in mind (ideas, recommendation ...etc), feel free to contact us anytime.</span>
            <a className='text-[#431a84]' href='https://mail.google.com/mail/u/0/?fs=1&tf=cm&source=mailto&to=techmentors.co@gmail.com'> info@TechMentors.co</a>
          </div>
      </div>
      <div className='md:w-[50vw] h-[65vh] m-auto' ><lottie-player src='https://assets1.lottiefiles.com/private_files/lf30_vAtD7F.json' background="transparent" speed="1" className='object-cover' loop={true} autoplay="true"/></div>
    </div>
    </>
  );
}

export default App;
